<template>
  <div>
    <login-box />
    <h1>Success Verification</h1>
  </div>
</template>
<script>
import LoginBox from "@/components/Auth/Login";
export default {
  components: {
    LoginBox,
  },
};
</script>
